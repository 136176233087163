import { TeliaHeading } from '@teliads/components/react';
import { navigate, PageProps } from 'gatsby';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Layout } from '../components/Layout';
import { ContentfulGuideWrapper } from '../models/ContentfulTypes';

interface Props extends PageProps {
  pageContext: {
    deviceGuide: ContentfulGuideWrapper;
    contentfulId: string;
    nextPageUrl?: string;
  };
}

export default ({ pageContext }: Props) => {
  const { deviceGuide, nextPageUrl, contentfulId } = pageContext;
  useEffect(() => {
    if (nextPageUrl) {
      navigate(nextPageUrl);
    }
  }, []);

  return (
    <Layout contentfulId={contentfulId} title={''}>
      {nextPageUrl ? (
        <div className=" center">
          <TeliaHeading tag={'h2'} variant={'title-100'}>
            {deviceGuide.title}
          </TeliaHeading>
        </div>
      ) : (
        <p>
          'There is no checklist, steps and page divider assigned to this page'
        </p>
      )}
    </Layout>
  );
};
